import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import React, { useMemo } from 'react';
import { ContextualMenuItemType } from '@fluentui/react/lib/ContextualMenu';
import { FocusZoneDirection } from '@fluentui/react/lib/FocusZone';
import { IContextMenuProps } from './interfaces';
import { StyledContextualMenu } from './styles';
import FilterInput from '../../../../FilterInput/FilterInput';
import { ISorting } from '../interfaces';
import { IStore, useObjectManagementStore } from '../store';

/**
 * Компонент Контекстного меню
 */
const ContextMenu: React.FC<IContextMenuProps> = ({ selectedTable, updateTable }) => {
    const filters = useObjectManagementStore((state: IStore) => state.filters);
    const contextMenu = useObjectManagementStore((state: IStore) => state.contextMenu);
    const toggleContextMenu = useObjectManagementStore((state: IStore) => state.toggleContextMenu);
    const changeFilters = useObjectManagementStore((state: IStore) => state.changeFilters);
    const changeSorting = useObjectManagementStore((state: IStore) => state.changeSorting);
    const resetFilters = useObjectManagementStore((state: IStore) => state.resetFilters);

    const { t } = useTranslation();

    const onHideContextualMenu = () => {
        toggleContextMenu({ show: false });
    };

    const changeValue = (value: string | number, mode?: string) => {
        contextMenu?.cell?.colName && changeFilters({ value, colName: contextMenu?.cell?.colName, mode });
    };

    const _resetFilters = () => {
        resetFilters();
    };

    const sortTable = (args: ISorting) => () => {
        changeSorting(args);
    };

    function renderFilterItem(): JSX.Element {
        if (!contextMenu?.cell?.colName) return <></>;
        return (
            <FilterInput
                inputArray={selectedTable.tableData}
                matchFields={[contextMenu.cell?.colName || '']}
                value={filters[contextMenu.cell?.colName]?.filter || ''}
                changeValue={changeValue}
                isOutsideControlled
            />
        );
    }

    // function renderCSVItem() {
    //     if (!contextMenu?.cell?.colName) return <></>;
    //     const selectedBranch = selectedTableId?.split('/')[0] || '';
    //     const selectedTable = tables[selectedBranch].filter((item) => item.tableId === selectedTableId)[0];
    //     const data = selectedTable.tableData.map((row) => {
    //         const result: { [x: string]: string } = {};
    //         Object.keys(row).forEach((cell) => {
    //             const val = row[cell as keyof typeof row];
    //             result[cell] = commonTools.isObject(val) ? JSON.stringify(val) : String(val);
    //         });
    //         return result;
    //     });

    //     return (
    //         <Container>
    //             <StyledCSVLink
    //                 data={data}
    //                 separator={';'}
    //                 filename={`${selectedTableId}.csv`}
    //                 className={'ms-ContextualMenu-itemText'}
    //             >
    //                 {`${t('Download .csv')}`}
    //             </StyledCSVLink>
    //         </Container>
    //     );
    // }

    const menuItems = [
        {
            key: 'filters',
            itemType: ContextualMenuItemType.Section,
            sectionProps: {
                topDivider: true,
                bottomDivider: true,
                title: `${t('Filters')}`,
                items: [
                    {
                        key: 'filter',
                        text: `Filter ${contextMenu.cell?.colName}`,
                        // onClick: () => {},
                        subMenuProps: {
                            focusZoneProps: { direction: FocusZoneDirection.bidirectional },
                            items: [
                                {
                                    key: 'filter',
                                    text: 'Filter',
                                    onRender: renderFilterItem,
                                },
                            ],
                        },
                    },
                    {
                        key: 'filterByValue',
                        text: `${t('Filter by value')}`,
                        onClick: () => changeValue(contextMenu.cell?.value || ''),
                    },
                    {
                        key: 'strictFilterByValue',
                        text: `${t('Strict filter by value')}`,
                        onClick: () => changeValue(contextMenu.cell?.value || '', 'strict'),
                    },
                    {
                        key: 'reset',
                        text: `Reset filters`,
                        onClick: _resetFilters,
                    },
                ],
            },
        },
        {
            key: 'sort',
            itemType: ContextualMenuItemType.Section,
            sectionProps: {
                bottomDivider: true,
                title: `${t('Sort')}`,
                items: [
                    {
                        key: 'direct',
                        text: `${t('Direct sort')} ${contextMenu.cell?.colName}`,
                        onClick: sortTable({ colName: contextMenu.cell?.colName as string, order: 1 }),
                    },
                    {
                        key: 'reverse',
                        text: `${t('Reverse sort')} ${contextMenu.cell?.colName}`,
                        onClick: sortTable({ colName: contextMenu.cell?.colName as string, order: -1 }),
                    },
                ],
            },
        },
        // {
        //     key: 'csv',
        //     text: `${t('Download .csv')}`,
        //     onRender: renderCSVItem,
        // },
    ];

    return useMemo(() => {
        return (
            <StyledContextualMenu
                items={menuItems}
                hidden={!contextMenu.show}
                target={contextMenu.cursorCoords}
                onItemClick={onHideContextualMenu}
                onDismiss={onHideContextualMenu}
            />
        );
    }, [menuItems, contextMenu.show, contextMenu.cursorCoords, onHideContextualMenu, filters]);
};

export default ContextMenu;
