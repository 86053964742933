import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TextField } from '@fluentui/react/lib/TextField';

import { filterListOfObjects } from './core/filterListOfObjects';
import { IFilterInputProps } from './FilterInput.interfaces';

/**
 * Компонента инпута с возможностью фильтрации
 */

const FilterInput = ({
    disabled,
    inputArray,
    updateFilteredArray,
    changeValue,
    matchFields = [],
    value = '',
    isOutsideControlled = false,
}: IFilterInputProps) => {
    const { t } = useTranslation();
    const [filterValue, setFilterValue] = useState(value);

    useEffect(() => {
        if (!inputArray?.length) return;
        if (isOutsideControlled) {
            return;
        } else {
            const filteredArray = filterListOfObjects(inputArray, matchFields, filterValue);
            updateFilteredArray && updateFilteredArray(filteredArray);
        }
    }, [filterValue, isOutsideControlled, inputArray]);

    const onChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        if (isOutsideControlled) {
            changeValue && changeValue(newValue || '');
        } else {
            setFilterValue(newValue || '');
        }
    };

    return (
        <TextField
            disabled={disabled}
            placeholder={t('Filter')}
            value={isOutsideControlled ? value : filterValue}
            onChange={onChange}
        />
    );
};

export default FilterInput;
