import styled from 'styled-components/macro';

export const MainWrapper = styled.section`
    // height: 95vh;
    height: calc(95vh - 90px);

    width: 95vw;
    overflow: none;
`;

export const UsersWrapper = styled.section<{ bgcolor: string; color: string }>`
    height: calc(95vh - 30px);
    overflow: hidden;
    background-color: ${(p) => p.bgcolor};
    color: ${(p) => p.color};
    padding: 10px;
`;
