import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import {
    buildColumns,
    ConstrainMode,
    DetailsList,
    DetailsListLayoutMode,
    DetailsRow,
    IColumn,
    IDetailsHeaderProps,
    IDetailsRowProps,
} from '@fluentui/react/lib/DetailsList';
import { useTranslation } from 'react-i18next';
import { ScrollablePane, ScrollbarVisibility, Sticky, StickyPositionType } from '@fluentui/react';
import { useDispatch } from 'react-redux';

import { GeneralContext } from '../../../../../../General.context';
import { ListWrapper } from '../../../List/List.styles';
import { IItems } from './interfaces';
import { ILocation } from '../../../../../Locations/Locations.interfaces';
import { commonTools } from '../../../../../Layers/layers';

const LocationsList: React.FC<{ onBeforeNavigate: () => void; list?: ILocation[] }> = ({ onBeforeNavigate, list }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { theme } = useContext(GeneralContext);
    const [columns, setColumns] = useState<IColumn[]>([]);
    const [sortedItems, setSortedItems] = useState<IItems>([]);

    const { themeLighterAlt, white } = theme.palette;
    const { t } = useTranslation();

    const items = (list || []).map((location) => {
        const { name, id, project_id } = location;

        // return { navigate: `/project/${project_id}/pl/${id}`, name, id, projectId: project_id };
        // return { navigate: `/project/${project_id}`, name, id, projectId: project_id };
        return { name, ['location Id']: id, ['project Id']: project_id };
    });

    useEffect(() => {
        const columns = myBuildColumns(items);

        setColumns(columns);
        setSortedItems(items);
    }, [list]);

    function myBuildColumns(items: IItems) {
        if (items.length === 0) return [];
        const columns = buildColumns(items);

        return columns;
    }

    const onNavigateUrl = (url: string) => () => {
        navigate(url);
        onBeforeNavigate?.();
        // dispatch(changeActiveProjectId({ id }));
    };

    const renderRow = (props: IDetailsRowProps | undefined) => {
        const customStyles = {
            root: {
                display: 'flex',
                backgroundColor: white,
            },
            cell: {
                display: 'flex',
                width: 'auto',
                alignItems: 'center',
            },
        };

        if (props) {
            if (props.itemIndex % 2 === 0) {
                customStyles.root = { display: 'flex', backgroundColor: themeLighterAlt };
            }

            return (
                <div>
                    <DetailsRow {...props} styles={customStyles} />
                </div>
            );
        } else {
            return null;
        }
    };

    function renderItemColumn(item: { [x: string]: any }, index: any, column: IColumn | undefined) {
        if (!column || !column?.fieldName) {
            return null;
        }

        switch (column.key!) {
            case 'navigate': {
                return null;
                // <DefaultButton
                //     text={t('Jump to project')}
                //     onClick={onNavigateUrl(item.navigate)}
                //     allowDisabledFocus
                // />
            }
            case 'location Id': {
                return (
                    <Link to={`/project/${item?.['project Id']}/pl/${item?.['location Id']}`}>{`${t('Jump to location')} ${item?.['location Id']}`}</Link>
                );
            }
            case 'project Id': {
                return <Link to={`/project/${item?.['project Id']}`}>{`${t('Jump to project')} ${item?.['project Id']}`}</Link>;
            }

            default: {
                const fieldContent = item[column.fieldName];

                return <span>{fieldContent}</span>;
            }
        }
    }

    const onColumnClick = (event: React.MouseEvent<HTMLElement> | undefined, column: IColumn | undefined): void => {
        const allowedKeys = ['id', 'name', 'location Id', 'project Id'];
        if (!column || !allowedKeys.includes(column.key)) return;
        let isSortedDescending = column.isSortedDescending;

        // If we've sorted this column, flip it.
        if (column.isSorted) {
            isSortedDescending = !isSortedDescending;
        }

        // Sort the items.
        const items = commonTools.copyAndSort(sortedItems, column.fieldName!, isSortedDescending);

        // Reset the items and columns to match the state.
        setSortedItems(items);
        setColumns(
            columns.map((col) => {
                col.isSorted = col.key === column.key;
                if (col.isSorted) {
                    col.isSortedDescending = isSortedDescending;
                }
                return col;
            }),
        );
    };

    const customTableHeader = (
        headerProps: IDetailsHeaderProps | undefined,
        defaultRender: ((props?: IDetailsHeaderProps | undefined) => JSX.Element | null) | undefined,
    ) => {
        if (!defaultRender) return null;
        return (
            <Sticky
                stickyPosition={StickyPositionType.Header}
                isScrollSynced={true}
                stickyBackgroundColor="transparent"
            >
                <div>{defaultRender(headerProps)}</div>
            </Sticky>
        );
    };

    return (
        <ListWrapper>
            <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
                <DetailsList
                    onRenderDetailsHeader={customTableHeader}
                    layoutMode={DetailsListLayoutMode.fixedColumns}
                    onColumnHeaderClick={onColumnClick}
                    items={sortedItems}
                    onRenderItemColumn={renderItemColumn}
                    setKey="set"
                    columns={columns}
                    onRenderRow={renderRow}
                    selectionMode={0}
                    constrainMode={ConstrainMode.unconstrained}
                    // constrainMode={0}
                    isHeaderVisible={true}
                />
            </ScrollablePane>
        </ListWrapper>
    );
};

export default LocationsList;
