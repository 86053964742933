import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FontIcon } from '@fluentui/react/lib/Icon';

import {
    saveLayerToServer,
    storeActiveLayerId,
    toggleVisible,
    editorReducerValues,
    changeNonValidLayersIds,
    changeShowLabelsIds,
    toggleContextMenu,
    storeHotAddObject,
} from '../../Editor.reducer';
import { toggleAlert } from '../../../../General.reducer';
import { locationsReducerValues } from '../../../Locations/Locations.reducer';
import { tablesPanelReducerValues, selectTable } from '../TablesPanel/TablesPanel.reducer';
import { tenantsReducerValues } from '../Tenants/Tenants.reducer';
import { versionsReducerValues } from '../../../Versions/Versions.reducer';
import { ControlPanelWrapper, Footer, PivotWrapper, Split, StyledPivot } from './ControlPanel.styles';
import { IControlPanelProps } from './ControlPanel.interfaces';
import { EditorContext } from '../../Editor.context';
import { GeneralContext } from '../../../../General.context';

import Accordion from '../../../Accordion/Accordion';
import Chapters from './components/Chapters/Chapters';
import { IContextMenu, ILayer, IPlan } from '../../../Layers/layers.interfaces';
import { ISaveLayerToServerArgs } from '../../../Accordion/components/AccordionItem/AccordionItem.interfaces';
import { layerNamesMapping } from '../../../../constants/layerNamesMapping';
import { getAccordionData } from './core/getAccordionData';
import { Pivot, PivotItem, PrimaryButton, TooltipHost, DefaultButton, Icon, IIconProps, Stack } from '@fluentui/react';
import TuplesList from './components/TuplesList/TuplesList';
import ToolsPanel from '../ToolsPanel/ToolsPanel';
import { useHotkeys } from 'react-hotkeys-hook';
import { useCallback } from 'react';
import Modal from '../../../Modals/Modals';
import { theme } from '../../../../constants/theme';
import DeletePlanModal from '../../../Modals/components/DeletePlanModal/DeletePlanModal';
import SaveLayerModal from '../../../Modals/components/SaveLayerModal/SaveLayerModal';
import TablesList from './components/TablesList/TablesList';
import { ITableListItem } from '../TablesPanel/TablesPanel.interfaces';
import TenantsList from './components/TenantsList/TenantsList';
import MonitoringList from './components/MonitoringList/MonitoringList';
import GroupsAccordion from './components/Accordion/Accordion';
import { layerGroups } from '../../../../constants/layerGroups';
import { fetchSync, makeSync, SyncPanelReducerValues } from '../SyncPanel/SyncPanel.reducer';
import { IModifications } from '../SyncPanel/SyncPanelInterfaces';
import { IToggleValidateAlertArgs } from '../../../../tools/commonInterfaces';
import { IAlert } from '../../../../General.interfaces';
import { useAppDispatch } from '../../../../tools/useSettings';
import LocationData from './components/Chapters/LocationData/LocationData';
import { patchLocation } from '../../../Locations/Locations.reducer';
import { use } from 'i18next';

/**
 * Компонент Контрольная панель
 * @param {(args: object) => void} layerChange - Callback вызываемый при любом изменении объектов слоя. Нужен для того, чтобы основное приложение имело информацию об изменениях внутри слоя.
 * @param {(args: object) => void} layerChange - Callback вызываемый при любом изменении объектов слоя. Нужен для того, чтобы основное приложение имело информацию об изменениях внутри слоя.
 * @param {IInitialLayers} initialLayers - Данные о слоях, полученных с сервера. Используется чтобы знать, произошли ли изменения.
 */
const ControlPanel = ({ layerChange, otherLayersChange, initialLayers, mainPlan }: IControlPanelProps) => {
    const {
        dataFromLayers,
        showLabelsIds,
        activeLayerId,
        selectedObjectId,
        allInitialLayers,
        nonValidLayersIds,
        selectedChapter,
        changedLayersIds,
        hotAddObject,
        savedLayersFlag,
    } = useSelector(editorReducerValues);
    const intervalRef = useRef<NodeJS.Timer | null>(null);
    const { selectedTableId, tables } = useSelector(tablesPanelReducerValues);
    const { activeProjectLocation } = useSelector(locationsReducerValues);
    const { tenant2place, places, tenants } = useSelector(tenantsReducerValues);
    const { modifications } = useSelector(SyncPanelReducerValues);
    const { activeVersionId } = useSelector(versionsReducerValues);
    const { layers, currentPlanData, visibleLayersIds, plansList, generalSettings } = useContext(EditorContext);
    const {
        urls: { LAYERS_URL, PROJECT_LOCATIONS_URL },
        token,
    } = useContext(GeneralContext);

    const [clipboardData, setClipboardData] = useState<any | null>(null);
    const [layerIdToRevert, setLayerIdToRevert] = useState<string>('');
    const [deletePlanStatus, setDeletePlanStatus] = useState<{ show: boolean; currentPlanData?: IPlan }>({
        show: false,
    });
    const [saveLayerStatus, setSaveLayerStatus] = useState<{
        show: boolean;
        saveLayerData?: { id: string; layersUrl: string };
    }>({
        show: false,
    });
    const [hotSaveCounter, setHotSaveCounter] = useState<number>(0);
    const [hotRevertCounter, setHotRevertCounter] = useState<number>(0);
    const [hotShowLabels, setHotShowLabels] = useState<number>(0);
    const [hotAddCounter, setHotAddCounter] = useState<number>(0);
    const [hotMoveCounter, setHotMoveCounter] = useState<number>(0);

    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    useHotkeys(
        'shift+r',
        () => {
            !generalSettings?.readOnly && setHotRevertCounter((prev) => prev + 1);
        },
        [generalSettings],
    );

    useHotkeys(
        'shift+s',
        () => {
            !generalSettings?.readOnly && setHotSaveCounter((prev) => prev + 1);
        },
        [generalSettings],
    );

    useHotkeys(
        'shift+e',
        () => {
            !generalSettings?.readOnly && setHotMoveCounter((prev) => prev + 1);
        },
        [generalSettings],
    );

    useHotkeys(
        'shift+a',
        () => {
            !generalSettings?.readOnly && setHotAddCounter((prev) => prev + 1);
        },
        [generalSettings],
    );

    useHotkeys(
        'shift+l',
        () => {
            !generalSettings?.readOnly && setHotShowLabels((prev) => prev + 1);
        },
        [generalSettings],
    );

    // Проверка на изменение массива changedLayersIds
    useEffect(() => {
        // Если массив непустой:
        if (changedLayersIds.length > 0) {
            // Запускаем интервал, только если его ещё нет
            if (!intervalRef.current) {
                intervalRef.current = setInterval(() => {
                    dispatch(
                        toggleAlert({
                            show: true,
                            type: 'warning',
                            text: t('You need to save changed layers: ') + changedLayersIds.join(', '),
                        }),
                    );
                }, 60 * 1000 * 5);
            }
        } else {
            // Если массив пустой, останавливаем интервал (если был)
            if (intervalRef.current) {
                clearInterval(intervalRef.current as NodeJS.Timeout);
                intervalRef.current = null;
            }
        }

        // Чистим интервал при размонтировании или при обновлении эффекта:
        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current as NodeJS.Timeout);
                intervalRef.current = null;
            }
        };
    }, [changedLayersIds]);

    useEffect(() => {
        activeLayerId && dispatch(storeHotAddObject({ activeLayerId, toolId: 'anchorsEdit' }));
    }, [hotMoveCounter, activeLayerId, dispatch]);

    useEffect(() => {
        activeLayerId && hotAddCounter && dispatch(storeHotAddObject({ activeLayerId, toolId: 'draw' }));
    }, [hotAddCounter]);

    useEffect(() => {
        activeLayerId && hotShowLabels && dispatch(changeShowLabelsIds(activeLayerId));
    }, [hotShowLabels]);

    useEffect(() => {
        activeLayerId &&
            changedLayersIds.includes(activeLayerId) &&
            setSaveLayerStatus({ show: true, saveLayerData: { id: activeLayerId, layersUrl: LAYERS_URL } });
    }, [hotSaveCounter]);

    useEffect(() => {
        activeLayerId && changedLayersIds.includes(activeLayerId) && revertLayer({ id: activeLayerId });
    }, [hotRevertCounter]);

    const selectLayer = (id: string) => {
        dispatch(storeActiveLayerId(id));
        !visibleLayersIds.includes(id) && dispatch(toggleVisible(id));
    };

    const onEyeClick = (id: string) => {
        // if (id === activeLayerId) return;
        dispatch(toggleVisible(id));
    };

    const saveLayerToServerTrans = (args: ISaveLayerToServerArgs) => {
        setSaveLayerStatus({ show: true, saveLayerData: { ...args, layersUrl: LAYERS_URL } });
    };

    const revertLayer = (args: { id: string }) => {
        setLayerIdToRevert(() => args.id);
        setTimeout(() => {
            setLayerIdToRevert(() => '');
        }, 100);
    };

    const onShowLabelIconClickTrans = (layerId: string) => {
        dispatch(changeShowLabelsIds(layerId));
    };

    const onPasteIconClickTrans = (clipboardData: any) => {
        setClipboardData({ ...clipboardData });
    };

    const toggleContextMenuTrans = (args: IContextMenu) => {
        dispatch(toggleContextMenu(args));
    };

    const toggleValidateAlert = (args: IToggleValidateAlertArgs) => {
        dispatch(changeNonValidLayersIds(args));
        const text = `${t('There are the same markers or names >> ')}
        floor: ${args.floor}, layer: ${args.id}
        names/markers: ${args?.names?.join(', ')}`;
        args.floor && console.warn(text);
        args.floor && dispatch(toggleAlert({ show: args.show, text }));
    };

    const toggleAlertTrans = (data: IAlert) => {
        dispatch(toggleAlert(data));
    };

    const data = layers.map((item: ILayer) => {
        const anotherLocationLayers = allInitialLayers
            .filter((lay) => lay.layerType === item.id)
            .filter((item) => item.mainPlanId !== mainPlan.planId);

        const result = getAccordionData({
            item,
            initialLayers,
            dataFromLayers,
            layerNamesMapping,
            activeLayerId,
            showLabelsIds,
            visibleLayersIds,
            selectedObjectId,
            changedLayersIds,
            nonValidLayersIds,
        });

        const dataFromClipboard = item.id === clipboardData?.layer_type ? clipboardData : null;

        const ObjectsList = item.controls.objectsList;
        const revertLayer = item.id === layerIdToRevert;

        const tenantsData = { tenant2place, places, tenants };
        const versionsData = { activeVersionId };

        const objects = (
            <ObjectsList
                key={item.id}
                id={item.id}
                hotAddObject={hotAddObject}
                revertLayer={revertLayer}
                active={result.active}
                visible={result.visible}
                currentPlanData={currentPlanData}
                initialData={result.initialData}
                showLabels={result.showLabels}
                layerChange={layerChange}
                otherLayersChange={otherLayersChange}
                selectedObjectId={selectedObjectId}
                tenantsData={tenantsData}
                versionsData={versionsData}
                plansList={plansList}
                clipboardData={dataFromClipboard}
                selectedChapter={selectedChapter}
                toggleContextMenu={toggleContextMenuTrans}
                toggleValidateAlert={toggleValidateAlert}
                generalSettings={generalSettings}
                anotherLocationLayers={anotherLocationLayers}
                toggleAlertTrans={toggleAlertTrans}
            />
        );
        return { ...result, objects };
    });

    const onDeletePlanClick = () => {
        setDeletePlanStatus({ show: true, currentPlanData });
    };

    const closeSaveLayerModal = () => {
        setSaveLayerStatus({ show: false });
    };

    const selectTableTrans = (tableId: string | null) => {
        dispatch(selectTable(tableId));
    };

    const onSyncClick = () => {
        dispatch(makeSync());
    };

    const onUpdateClick = () => {
        dispatch(
            patchLocation({
                url: PROJECT_LOCATIONS_URL,
                token,
                locationId: String(activeProjectLocation?.id),
                data: { project_id: activeProjectLocation?.project_id },
                reloadWindow: true,
            }),
        );
    };

    const onCheckDiffClick = () => {
        dispatch(fetchSync());
    };

    const groupsAccordeon = layerGroups.map((group) => {
        const localData = group.layerAliases.map((alias) => {
            return data.filter((item) => item.layerId === alias)[0];
        });

        return activeLayerId && activeLayerId !== 'measureCut' ? (
            <GroupsAccordion title={t(group.fullName)} key={group.fullName}>
                <Accordion
                    data={localData}
                    visible={selectedChapter === 'layers'}
                    selectLayer={selectLayer}
                    dataFromLayers={dataFromLayers}
                    initialLayers={initialLayers}
                    onEyeClick={onEyeClick}
                    saveLayerToServer={saveLayerToServerTrans}
                    revertLayer={revertLayer}
                    activeLayerId={activeLayerId}
                    onShowLabelIconClick={onShowLabelIconClickTrans}
                    onPasteIconClick={onPasteIconClickTrans}
                />
            </GroupsAccordion>
        ) : null;
    });

    const syncButtonDisabled =
        !modifications ||
        Object.keys(modifications).every((key) => modifications[key as keyof IModifications].length === 0);

    const syncIcon: IIconProps = { iconName: 'Sync' };
    const searchIcon: IIconProps = { iconName: 'Search' };
    const cacheIcon: IIconProps = { iconName: 'Link' };

    return (
        <ControlPanelWrapper>
            <Modal modalStatus={deletePlanStatus} topColor={theme.palette.red} title={t('Delete plan')}>
                <DeletePlanModal planToDelete={deletePlanStatus.currentPlanData} />
            </Modal>

            <Modal modalStatus={saveLayerStatus} topColor={theme.palette.red} title={t('Save layer')}>
                <SaveLayerModal modalStatus={saveLayerStatus} closeModal={closeSaveLayerModal} />
            </Modal>
            <Chapters />
            <Split />
            {selectedChapter === 'layers' && !generalSettings?.readOnly && (
                <>
                    <ToolsPanel />
                    <Split />
                </>
            )}
            {activeLayerId === 'measureCut' && (
                <Accordion
                    data={data}
                    visible={true}
                    selectLayer={selectLayer}
                    dataFromLayers={dataFromLayers}
                    initialLayers={initialLayers}
                    onEyeClick={onEyeClick}
                    saveLayerToServer={saveLayerToServerTrans}
                    revertLayer={revertLayer}
                    activeLayerId={activeLayerId}
                    onShowLabelIconClick={onShowLabelIconClickTrans}
                />
            )}
            {['layers', 'tuples', 'tenants', 'monitoring'].includes(selectedChapter) && groupsAccordeon}

            {selectedChapter === 'sync' && (
                <Stack>
                    <LocationData />
                    <Split />
                    <PrimaryButton
                        style={{ marginRight: 10, marginBottom: 10, marginTop: 10 }}
                        text={t('Check changes')}
                        iconProps={searchIcon}
                        allowDisabledFocus
                        onClick={onCheckDiffClick}
                    />
                    <PrimaryButton
                        style={{ marginRight: 10, marginBottom: 10 }}
                        text={t('Synchronize')}
                        iconProps={syncIcon}
                        allowDisabledFocus
                        onClick={onSyncClick}
                        disabled={syncButtonDisabled}
                    />
                    <PrimaryButton
                        style={{ marginRight: 10, marginBottom: 10 }}
                        text={t('Update cache')}
                        iconProps={cacheIcon}
                        allowDisabledFocus
                        onClick={onUpdateClick}
                    />
                </Stack>
            )}

            <TuplesList visible={selectedChapter === 'tuples'} />

            <TablesList
                visible={selectedChapter === 'tables'}
                selectTableTrans={selectTableTrans}
                tables={tables}
                savedLayersFlag={savedLayersFlag}
                // plIds={plIds}
                selectedTableId={selectedTableId}
            />

            <TenantsList
                visible={selectedChapter === 'tenants'}
                onShowLabelIconClick={onShowLabelIconClickTrans}
                showLabelsIds={showLabelsIds}
            />
            <MonitoringList visible={selectedChapter === 'monitoring'} />

            <Footer>
                {currentPlanData?.planId && activeLayerId === 'measureCut' && (
                    <DefaultButton text={t('Delete plan')} allowDisabledFocus onClick={onDeletePlanClick} />
                )}
            </Footer>
        </ControlPanelWrapper>
    );
};

export default ControlPanel;
