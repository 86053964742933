import { useQuery } from '@tanstack/react-query';
import { GET_ALL_USERS_QUERY_KEY } from '../constants/queryKeys';
import { ITableUser, IUser } from '../components/Projects/components/UsersModal/interfaces';
import { getRequest } from '../tools/api';
import { useSelector } from 'react-redux';
import { generalReducerValues } from '../General.reducer';
import { projectsReducerValues } from '../components/Projects/reducer';

export const useGetUsers = () => {
    const {
        urls: { USERS_URL },
        token,
    } = useSelector(generalReducerValues);
    const { projectsById } = useSelector(projectsReducerValues);

    const enabled = Boolean(USERS_URL) && Boolean(token) && Object.keys(projectsById).length > 0;

    const select = (dataReq: IUser[]): ITableUser[] => {
        return dataReq
            .filter((item) => Boolean(item.project_id))
            .map((item) => {
                return {
                    projectName: projectsById[item.project_id ? item.project_id : 0].name,
                    id: item.id,
                    projectId: item.project_id,
                    username: item.username,
                };
            }) as ITableUser[];
    };

    const { data, isFetching } = useQuery({
        queryKey: [GET_ALL_USERS_QUERY_KEY],
        queryFn: async () => {
            const res: IUser[] = await getRequest({ url: USERS_URL, token });
            return res;
        },
        staleTime: 15 * 60 * 1000,
        select,
        enabled,
    });

    return { data, isFetching };
};
