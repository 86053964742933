import React, { useContext, useState } from 'react';

import StackHorizontal from '../../../StackHorizontal/StackHorizontal';
import FilterInput from '../../../FilterInput/FilterInput';
import { GeneralContext } from '../../../../General.context';

import { MainWrapper, UsersWrapper } from './styles';
import { CSSProp } from 'styled-components';
import { Spinner } from '@fluentui/react/lib/Spinner';
import UsersList from './components/UsersList/UsersList';
import { ITableUser } from './interfaces';
import { useGetUsers } from '../../../../api/useGetUsers';

export interface UsersModalProps {
    closeModal: () => void;
}

export const UsersModal: React.FC<UsersModalProps> = ({ closeModal }) => {
    const { theme } = useContext(GeneralContext);
    const { neutralLighterAlt, neutralDark } = theme.palette;

    const [filteredList, setFilteredList] = useState<ITableUser[]>([]);
    const { data: users, isFetching } = useGetUsers();


    const updateFilteredArray = (filteredArray: Array<object>) => {
        setFilteredList(filteredArray as ITableUser[]);
    };

    
    const allUsers = users || ([] as ITableUser[]);

    const styles: CSSProp = {
        minHeight: 400,
        minWidth: 600,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    };

    if (isFetching) {
        return (
            <div style={styles}>
                <Spinner size={3} />
            </div>
        );
    }

    return (
        <MainWrapper>
            <StackHorizontal>
                <FilterInput
                    inputArray={allUsers}
                    updateFilteredArray={updateFilteredArray}
                    matchFields={['id', 'projectId', 'username', 'projectName']}
                />
            </StackHorizontal>

            <UsersWrapper bgcolor={neutralLighterAlt} color={neutralDark}>
                <UsersList list={filteredList} onBeforeNavigate={closeModal} />
            </UsersWrapper>
        </MainWrapper>
    );
};
