import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import React, { useMemo } from 'react';
import { tablesPanelReducerValues, toggleContextMenu, changeFilters, changeSorting } from '../../TablesPanel.reducer';
import { ContextualMenuItemType } from '@fluentui/react/lib/ContextualMenu';
import { FocusZoneDirection } from '@fluentui/react/lib/FocusZone';
import FilterInput from '../../../../../FilterInput/FilterInput';
import { ISorting } from '../../TablesPanel.interfaces';
import { IContextMenuProps } from './interfaces';
import { Container, StyledContextualMenu, StyledCSVLink } from './styles';
import { commonTools } from '../../../../../../tools/commonTools';

/**
 * Компонент Контекстного меню
 */
const ContextMenu: React.FC<IContextMenuProps> = ({ selectedTable, updateTable }) => {
    const { contextMenu, filters, selectedTableId } = useSelector(tablesPanelReducerValues);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const onHideContextualMenu = () => {
        dispatch(toggleContextMenu({ show: false }));
    };

    const changeValue = (value: string, mode?: string) => {
        contextMenu?.cell?.colName && dispatch(changeFilters({ value, colName: contextMenu?.cell?.colName, mode }));
    };

    const reserFilters = () => {
        updateTable(null);
    };

    const sortTable = (args: ISorting) => () => {
        dispatch(changeSorting(args));
    };

    function renderFilterItem(): JSX.Element {
        if (!contextMenu?.cell?.colName) return <></>;
        return (
            <FilterInput
                inputArray={selectedTable.tableData}
                matchFields={[contextMenu.cell?.colName || '']}
                value={filters[contextMenu.cell?.colName]?.filter || ''}
                changeValue={changeValue}
                isOutsideControlled
            />
        );
    }

    function renderCSVItem() {
        if (!contextMenu?.cell?.colName) return <></>;
        const data = (selectedTable?.tableData || []).map((row) => {
            const result: { [x: string]: string } = {};
            Object.keys(row).forEach((cell) => {
                const val = row[cell as keyof typeof row];
                result[cell] = commonTools.isObject(val) ? JSON.stringify(val) : String(val);
            });
            return result;
        });

        return (
            <Container>
                <StyledCSVLink
                    data={data}
                    separator={';'}
                    filename={`${selectedTableId}.csv`}
                    className={'ms-ContextualMenu-itemText'}
                >
                    {`${t('Download .csv')}`}
                </StyledCSVLink>
            </Container>
        );
    }

    const menuItems = [
        {
            key: 'filters',
            itemType: ContextualMenuItemType.Section,
            sectionProps: {
                topDivider: true,
                bottomDivider: true,
                title: `${t('Filters')}`,
                items: [
                    {
                        key: 'filter',
                        text: `Filter ${contextMenu.cell?.colName}`,
                        // onClick: () => {},
                        subMenuProps: {
                            focusZoneProps: { direction: FocusZoneDirection.bidirectional },
                            items: [
                                {
                                    key: 'filter',
                                    text: 'Filter',
                                    onRender: renderFilterItem,
                                },
                            ],
                        },
                    },
                    {
                        key: 'filterByValue',
                        text: `${t('Filter by value')}`,
                        onClick: () => changeValue(contextMenu.cell?.value || ''),
                    },
                    {
                        key: 'strictFilterByValue',
                        text: `${t('Strict filter by value')}`,
                        onClick: () => changeValue(contextMenu.cell?.value || '', 'strict'),
                    },
                    {
                        key: 'reset',
                        text: `Reset filters`,
                        onClick: reserFilters,
                    },
                ],
            },
        },
        {
            key: 'sort',
            itemType: ContextualMenuItemType.Section,
            sectionProps: {
                bottomDivider: true,
                title: `${t('Sort')}`,
                items: [
                    {
                        key: 'direct',
                        text: `${t('Direct sort')} ${contextMenu.cell?.colName}`,
                        onClick: sortTable({ colName: contextMenu.cell?.colName as string, order: 1 }),
                    },
                    {
                        key: 'reverse',
                        text: `${t('Reverse sort')} ${contextMenu.cell?.colName}`,
                        onClick: sortTable({ colName: contextMenu.cell?.colName as string, order: -1 }),
                    },
                ],
            },
        },
        {
            key: 'csv',
            text: `${t('Download .csv')}`,
            onRender: renderCSVItem,
        },
    ];

    return useMemo(() => {
        return (
            <StyledContextualMenu
                items={menuItems}
                hidden={!contextMenu.show}
                target={contextMenu.cursorCoords}
                onItemClick={onHideContextualMenu}
                onDismiss={onHideContextualMenu}
            />
        );
    }, [menuItems, contextMenu.show, contextMenu.cursorCoords, onHideContextualMenu, filters]);
};

export default ContextMenu;
