import React, { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { projectsReducerValues } from './reducer';
import { generalReducerValues } from '../../General.reducer';
import { settingsReducerValues } from '../Settings/Settings.reducer';
import { LocationsWrapper } from './styles';
// import { IEditModalStatus, IHandleCreateNewLocationArgs, ILocation } from './interfaces';
import { GeneralContext } from '../../General.context';
import List from './components/List/List';
import StackHorizontal from '../StackHorizontal/StackHorizontal';
import FilterInput from '../FilterInput/FilterInput';
import { IProject } from './interfaces';
import { useAppDispatch } from '../../store';
import { PrimaryButton } from '@fluentui/react';
import Modals from '../Modals/Modals';
import { NewProjectModal } from './components/NewProjectModal/NewProjectModal';
import { LocationsModal } from './components/LocationsModal/LocationsModal';
import { UsersModal } from './components/UsersModal/UsersModal';

/**
 * Отображает список локаций (реальные отчетные объекты, обладающие набором свойств.)
 */
const Projects = React.memo(() => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const [modalStatus, setModalStatus] = useState({ show: false });
    const [locationsModalState, setLocationsModalState] = useState({ show: false });
    const [usersModalState, setUsersModalState] = useState({ show: false });

    const { projectsById, projectsList } = useSelector(projectsReducerValues);
    const {
        generalSettings: { readOnly },
    } = useSelector(settingsReducerValues);
    const {
        urls: { PROJECTS_URL },
    } = useSelector(generalReducerValues);
    const [filteredProjectsList, setFilteredProjectsList] = useState(projectsList);
    const { token, theme } = useContext(GeneralContext);
    const { neutralLighterAlt, neutralDark } = theme.palette;
    // const [editLocationModalStatus, setEditLocationModalStatus] = useState<IEditModalStatus>({
    //     show: false,
    //     modalData: null,
    // });

    // useEffect(() => {
    //     if (locationsList.length > 0) setEditLocationModalStatus({ show: false, modalData: null });
    // }, [locationsList]);

    const updateFilteredArray = (filteredArray: Array<object>) => {
        setFilteredProjectsList(filteredArray as IProject[]);
    };

    const onAddNewProjectClick = () => {
        setModalStatus({ show: true });
    };
    const closeModal = () => {
        setModalStatus({ show: false });
    };

    const onShowAllLocationsClick = () => {
        setLocationsModalState({ show: true });
    };

    const onShowAllUsersClick = () => {
        setUsersModalState({ show: true });
    };

    const onCloseAllLocationsClick = () => {
        setLocationsModalState({ show: false });
    };

    const onCloseAllUsersClick = () => {
        setUsersModalState({ show: false });
    };

    // const onCreateNewLocationClick = (data: ILocation) => {
    //     if (!data?.cloneChecked) {
    //         delete data.cloneId;
    //         dispatch(addNewLocation({ url: LOCATIONS_URL, token, data }));
    //     } else if (data?.cloneChecked) {
    //         dispatch(cloneLocation({ data }));
    //     }
    //     setModalStatus({ show: false });
    // };

    // const setEditLocationModalStatusTrans = (id: string) => {
    //     const locationForEdit = locationsList
    //         .filter((location: ILocation) => location.id)
    //         .find((location: ILocation) => location.id === id);

    //     if (locationForEdit) {
    //         setEditLocationModalStatus({ show: true, modalData: locationForEdit });
    //     } else {
    //         setEditLocationModalStatus({ show: false, modalData: null });
    //     }
    // };

    return projectsList && projectsList.length !== 0 ? (
        <>
            {/* <Modal modalStatus={editLocationModalStatus} title={t('Edit location')}>
                <EditLocationModal modalData={editLocationModalStatus.modalData} />
            </Modal> */}
            <Modals modalStatus={modalStatus} title={t('Create new project')}>
                <NewProjectModal closeModal={closeModal} />
            </Modals>
            <Modals modalStatus={locationsModalState} title={t('All locations')}>
                <LocationsModal closeModal={onCloseAllLocationsClick} />
            </Modals>
            <Modals modalStatus={usersModalState} title={t('All users')}>
                <UsersModal closeModal={onCloseAllUsersClick} />
            </Modals>

            <LocationsWrapper bgcolor={neutralLighterAlt} color={neutralDark}>
                <StackHorizontal>
                    <FilterInput
                        inputArray={projectsList}
                        updateFilteredArray={updateFilteredArray}
                        matchFields={['name', 'id']}
                    />
                    {!readOnly && (
                        <PrimaryButton text={t('Add new project')} onClick={onAddNewProjectClick} allowDisabledFocus />
                    )}

                    <PrimaryButton
                        text={t('View all locations')}
                        onClick={onShowAllLocationsClick}
                        allowDisabledFocus
                    />
                    <PrimaryButton text={t('View all users')} onClick={onShowAllUsersClick} allowDisabledFocus />
                </StackHorizontal>
                <List list={filteredProjectsList} />
            </LocationsWrapper>
        </>
    ) : null;
});

export default Projects;
