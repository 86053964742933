import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import {
    buildColumns,
    ConstrainMode,
    DetailsList,
    DetailsListLayoutMode,
    DetailsRow,
    IColumn,
    IDetailsHeaderProps,
    IDetailsRowProps,
} from '@fluentui/react/lib/DetailsList';
import { useTranslation } from 'react-i18next';
import { ScrollablePane, ScrollbarVisibility, Sticky, StickyPositionType } from '@fluentui/react';
import { useSelector } from 'react-redux';
import { GeneralContext } from '../../../../../../General.context';
import { ListWrapper } from '../../../List/List.styles';
import { IItems } from './interfaces';
import { commonTools } from '../../../../../Layers/layers';
import { ITableUser } from '../../interfaces';
import { projectsReducerValues } from '../../../../reducer';

const UsersList: React.FC<{ onBeforeNavigate: () => void; list?: ITableUser[] }> = ({ onBeforeNavigate, list }) => {
    const navigate = useNavigate();

    const { theme } = useContext(GeneralContext);
    const [columns, setColumns] = useState<IColumn[]>([]);
    const [sortedItems, setSortedItems] = useState<IItems>([]);
    const { projectsById } = useSelector(projectsReducerValues);

    const { themeLighterAlt, white } = theme.palette;
    const { t } = useTranslation();

    const items:IItems = (list || []).map((user) => {
        const { username, id, projectId, projectName } = user;

        return { ['user Id']: id, username,  ['project Id']: projectId, ['project name']: projectName };
    });

    useEffect(() => {
        const columns = myBuildColumns(items);

        setColumns(columns);
        setSortedItems(items);
    }, [list]);

    function myBuildColumns(items: IItems) {
        if (items.length === 0) return [];
        const columns = buildColumns(items);

        return columns;
    }

    const onNavigateUrl = (url: string) => () => {
        navigate(url);
        onBeforeNavigate?.();
        // dispatch(changeActiveProjectId({ id }));
    };

    const renderRow = (props: IDetailsRowProps | undefined) => {
        const customStyles = {
            root: {
                display: 'flex',
                backgroundColor: white,
            },
            cell: {
                display: 'flex',
                width: 'auto',
                alignItems: 'center',
            },
        };

        if (props) {
            if (props.itemIndex % 2 === 0) {
                customStyles.root = { display: 'flex', backgroundColor: themeLighterAlt };
            }

            return (
                <div>
                    <DetailsRow {...props} styles={customStyles} />
                </div>
            );
        } else {
            return null;
        }
    };

    function renderItemColumn(item: { [x: string]: any }, index: any, column: IColumn | undefined) {
        if (!column || !column?.fieldName) {
            return null;
        }


        switch (column.key!) {
            case 'user Id': {
                const fieldContent = item[column.fieldName];

                return <span>{fieldContent}</span>;
            }
            case 'project Id': {
                if (!item?.['project Id']) {
                    return t('No project')
                }
                return <Link to={`/project/${item?.['project Id']}`}>{`${t('Jump to project')} ${item?.['project Id']}`}</Link>;
            }
            case 'username': {
                const fieldContent = item[column.fieldName];

                return <span>{fieldContent}</span>;
            }


            default: {
                const fieldContent = item[column.fieldName];

                return <span>{fieldContent}</span>;
            }
        }
    }

    const onColumnClick = (event: React.MouseEvent<HTMLElement> | undefined, column: IColumn | undefined): void => {
        const allowedKeys = ['user Id', 'username', 'project Id', 'project name'];
        if (!column || !allowedKeys.includes(column.key)) return;
        let isSortedDescending = column.isSortedDescending;

        // If we've sorted this column, flip it.
        if (column.isSorted) {
            isSortedDescending = !isSortedDescending;
        }

        // Sort the items.
        const items = commonTools.copyAndSort(sortedItems, column.fieldName!, isSortedDescending);

        // Reset the items and columns to match the state.
        setSortedItems(items);
        setColumns(
            columns.map((col) => {
                col.isSorted = col.key === column.key;
                if (col.isSorted) {
                    col.isSortedDescending = isSortedDescending;
                }
                return col;
            }),
        );
    };

    const customTableHeader = (
        headerProps: IDetailsHeaderProps | undefined,
        defaultRender: ((props?: IDetailsHeaderProps | undefined) => JSX.Element | null) | undefined,
    ) => {
        if (!defaultRender) return null;
        return (
            <Sticky
                stickyPosition={StickyPositionType.Header}
                isScrollSynced={true}
                stickyBackgroundColor="transparent"
            >
                <div>{defaultRender(headerProps)}</div>
            </Sticky>
        );
    };


    return (
        <ListWrapper>
            <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
                <DetailsList
                    onRenderDetailsHeader={customTableHeader}
                    layoutMode={DetailsListLayoutMode.fixedColumns}
                    onColumnHeaderClick={onColumnClick}
                    items={sortedItems}
                    onRenderItemColumn={renderItemColumn}
                    setKey="set"
                    columns={columns}
                    onRenderRow={renderRow}
                    selectionMode={0}
                    constrainMode={ConstrainMode.unconstrained}
                    isHeaderVisible={true}
                />
            </ScrollablePane>
        </ListWrapper>
    );
};

export default UsersList;
